<script>
import { drawLog } from "@/api/home";
import { drawGet } from "@/api/home";
export default {
    name:'LuckyDrawList',
    components: {
        Header: () => import('components/base/Header'),

        'mt-header': app.Mint.Header,
        'mt-button': app.Mint.Button,
    },
    directives: {
        'infinite-scroll': app.Mint.InfiniteScroll,
    },
    data() {
        return {
            bool: true,
            list: [],
            loading_data: true,
            page: 1,
            pageSize: 20,
            totalPage: "",

            header:{
                mainTitle: getWord(['withdraw2', 'bonus']),
            },
        };
    },
    methods: {
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        },
        //提取全部奖金
        commit() {
            drawGet().then(result => {
                if (result.data.code == "SUCCESS") {
                    this.drawLog();
                    app.Mint.Toast(getWord(['withdraw2', 'success']));
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
        },
        drawLog() {
            drawLog(this.page, this.pageSize).then(result => {
                if (result.data.code == "SUCCESS") {
                    this.list = this.list.concat(result.data.result.list);
                    this.totalPage = result.data.result.totalPage;
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
            this.loading_data = false;
        },
        //分页
        loadMore_data() {
            if (this.page >= this.totalPage) {
                return false;
            } else {
                this.page++;
                this.drawLog();
            }
        }
    },
    mounted() {
        this.drawLog();
        this.CustomScroll(this.$refs.withdrawBonus.id);
    },
    watch: {}
};
</script>
<template>
    <div id="withdraw-bonus" ref="withdrawBonus" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <mt-header :title="getWord(['withdraw2', 'bonus'])" style="height:0.88rem;font-size: 0.35rem;wdith:100%;">
                <mt-button icon="back" slot="left" @click="left_click()"></mt-button>
            </mt-header>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
        </template>
        <div v-if="! bool">
            <span class="no_money">暂时无奖金可提取!</span>
        </div>
        <div v-if="bool" class="inner">
            <table class="main_table" cellspacing="0px" v-infinite-scroll="loadMore_data" infinite-scroll-disabled="loading_data" infinite-scroll-distance="50">
                <tr>
                    <th>{{ getWord('bonus') }}</th>
                    <th>{{ getWord('amount_of_betting_required') }}</th>
                    <th>{{ getWord('valid_period') }}</th>
                    <th>{{ getWord('status') }}</th>
                </tr>
                <tr v-for="item in list">
                    <td>{{item.money}}</td>
                    <td>{{item.consume}}</td>
                    <td>
                        <div style="float:left;width:100%;text-align:center;">
                            <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">{{item.time}}</template>
                            <template v-if="_TEMPLATE==='template-3'">
                                {{item.time.split(' - ')[0]}}
                                <br />
                                {{item.time.split(' - ')[1]}}
                            </template>
                        </div>
                    </td>
                    <td>
                        <span v-if="item.status==0" style="color:#CCC;">{{ getWord('inactivation') }}</span>         
                        <span v-else-if="item.status===-1" style="color:#f00">{{ getWord('inactivated') }}</span>               
                        <span v-else-if="item.status===1" style="color:#00C63B">{{ getWord('activated') }}</span>
                        <span v-else-if="item.status===3" style="color:#333;">{{ getWord('obtained') }}</span>
                    </td>
                </tr>
            </table>
            <mt-button type="primary" class="but_css" @click="commit()">
                <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">{{ getWord('withdraw2') }}</template>
                <template v-if="_TEMPLATE==='template-3'">{{ getWord(['withdraw2','quanbu','bonus']) }}</template>
            </mt-button>
        </div>
    </div>
</template>
<style scoped lang="scss">
#withdraw-bonus {
    overflow: auto;

    &.template-3 {
        background-color: #EEEEEE;

        .inner {

            .main_table {

                th {
                    font-size: .3rem;
                    color: #B0B0B0;
                    background-color: #eeeeee;
                    border:0;
                    padding: .15rem 0;
                    font-weight: normal;
                }

                td {
                    border-top: 0;
                    border-left:0;
                    border-right:0;
                    border-color:#B0B0B0;
                    font-size: .28rem;
                    color: #666666;
                    height: auto;
                    padding: .1rem 0;
                }
            }

            .but_css {
                background-color: #EC2829;
                font-size: .34rem;
                width: auto;
                height: auto;
                padding: .25rem;
                display: block;
                width: 90%;
                box-sizing: border-box;
                margin:0 auto;
                margin-top: .5rem;
            }
        }
    }

    .inner {
        padding-bottom: 20px;

        .main_table {
            width: 100%;
            /* 表格单元格间距样式 */
            border-collapse: collapse;
            border: 1px solid rgba(187, 187, 187, 1);
            font-size: 0.3rem;
            text-align: center;
            box-shadow: 0 0 0.2rem #ccc;
            background-color: white;
        }

        .main_table tr th {
            font-size: 0.24rem;
            color: rgba(71, 150, 252, 1);
            border: 0.5px solid rgba(187, 187, 187, 1);
            height: 0.68rem;
        }

        .main_table tr td {
            border: 0.5px solid rgba(187, 187, 187, 1);
            font-size: 0.22rem;
            color: rgba(51, 51, 51, 1);
            height: 0.68rem;
            text-align: center;
        }

        .but_css {
            height: 0.88rem;
            width: 7.12rem;
            background: rgba(71, 150, 252, 1);
            border-radius: 0.1rem;
            margin: 0.3rem 0 0 0.2rem;
        }
    }

    .no_money {
        float: left;
        font-size: 0.36rem;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        margin: 4rem 2.2rem;
    }
}
</style>
